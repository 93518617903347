import React from "react"
import { Container } from "react-bootstrap"

const ThreeTeaser = ({title, content, title1, description1, title2, description2, title3, description3, icon1, icon2, icon3}) => {
  return (
    <section className="component threeTeaser my-200 py-5 py-md-0">
      <Container fluid >
        <div className="row justify-content-center mb-5">
          <div className="col-md-8 text-center">
            <h2 className="h4 mb-4">{title}</h2>
            <p>{content}</p>
          </div>
        </div>
        <div className="row items-wrapper">
          <div className="col-12 col-md-4 item text-center mb-5 mb-md-0">
            {/* <FontAwesomeIcon icon={faCalendarAlt} size="3x" /> */}
            <h2 className="h4 mt-4 mb-4">{title1}</h2>
            <p>{description1}</p>
          </div>
          <div className="col-12 col-md-4 item text-center mb-5 mb-md-0">
            {/* <FontAwesomeIcon icon={faUserFriends} size="3x" /> */}
            <h2 className="h4 mt-4 mb-4">{title2}</h2>
            <p>{description2}</p>
          </div>
          <div className="col-12 col-md-4 item text-center mb-5 mb-md-0">
            {/* <FontAwesomeIcon icon={faHome} size="3x" /> */}
            <h2 className="h4 mt-4 mb-4">{title3}</h2>
            <p>{description3}</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ThreeTeaser