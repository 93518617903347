import React, { useState } from "react"
import axios from "axios"

const SellingForm = () => {
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(true);

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [phone, setPhone] = useState('');

  const submitForm = (event) => {
    event.preventDefault();

    if (!name || !phone || !date) {
      setValidated(false)
      return false
    }

    setValidated(true)

    axios({
      method: "post",
      url: '/sendSellingMail.php',
      headers: { "content-type": "application/json" },
      data: {
        "name": name,
        "phone": phone,
        "date": date
      }
    })
      .then(result => {
        if (result.data.sent) {
          window.gtag('event', 'conversion', {'send_to': 'AW-601692041/GZtLCMOk2d8BEImv9J4C'})
          setSuccess(true)
        } else {
          setSuccess(true)
        }
      });
  }

  return (
    <section className="component form">
      <form id="ajaxForm" onSubmit={submitForm} method="POST">
        <div  style={{display: (!success) ? 'block' : 'none'}}>
            <div className="form-group d-flex flex-column align-items-start justify-content-start">
              <label style={{color: (!validated && name === '') ? 'red' : null}} htmlFor="name">Name *</label>
              <input type="text" className="form-control" id="name" aria-describedby="Name" placeholder="Name" onBlur={(event) => setName(event.target.value)} />
            </div>
            <div className="form-group d-flex flex-column align-items-start justify-content-start">
              <label style={{color: (!validated && phone === '') ? 'red' : null}} htmlFor="phone">Telefon *</label>
              <input type="tel" className="form-control" id="phone" aria-describedby="Phone" placeholder="Telefon" onBlur={(event) => setPhone(event.target.value)} />
            </div>
            <div className="form-group d-flex flex-column align-items-start justify-content-start">
              <label style={{color: (!validated && date === '') ? 'red' : null}} htmlFor="date">Tag / Uhrzeit *</label>
              <input type="datetime-local" className="form-control" id="date" aria-describedby="Rückruf am" placeholder="Rückruf am" onBlur={(event) => setDate(event.target.value)} />
            </div>
            <button type="submit" className="btn">Termin anfragen</button>
        </div>
        {success &&
          <div>
            <p className="color-blue">Vielen Dank! Ihre Anfrage wurde erfolgreich gesendet.</p>
          </div>
        }
      </form>
    </section>
  )
}

export default SellingForm
