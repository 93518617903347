import React from "react"
import { Container } from "react-bootstrap"

const BigHeader = ({title, description, img, formType}) => {
  const backgroundFluidImageStack = [
    img,
    `linear-gradient(180deg, rgb(255, 255, 255, 0.37) 0%, rgb(255, 255, 255, 0.57) 100%)`
  ].reverse()
  
  return (
    <section
      className={`component bigHeader bg-grey`}
    >
      <video className="bgVideo" autoPlay loop muted playsInline>
        <source src="/AdobeStock_197486224.mp4" type="video/mp4" />
      </video>
      <Container fluid className="textWrapper">
        <div className="row align-items-center">
          <div className="col-12 col-md-8">
            <h1 className="mb-2">{title}</h1>
            <p>{description}</p>
            <a href="#start" className="btn mt-4 mb-5">Jetzt bewerten</a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default BigHeader