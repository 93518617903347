import React from "react"
import { Container } from "react-bootstrap"
import Form from "./form"
import SellingForm from "./sellingForm"

const ContentTeaser = ({ title, description, additionalClass, formType }) => (
  <section className={`component customerReviews mb-5 ${additionalClass}`}>
    <Container fluid className="py-5">
      <div className="row align-items-center">
        <div className="col-12">
          <h2 className="h3 mb-4">{title}</h2>
          <p>{description}</p>
          {formType && formType === 'verkaufen' ?
            <SellingForm />
          : 
          <Form iframeHeight="400" />
          }
        </div>
      </div>
    </Container>
  </section>
)

export default ContentTeaser